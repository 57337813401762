exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-09-29-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/09/29/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-09-29-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-09-29-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/09/29/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-09-29-1-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-09-30-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/09/30/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-09-30-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-09-30-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/09/30/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-09-30-1-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-01-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/01/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-01-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-01-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/01/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-01-1-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-06-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/06/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-06-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-06-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/06/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-06-1-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-07-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/07/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-07-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-08-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/08/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-08-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-11-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/11/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-11-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-10-13-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/10/13/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-10-13-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2020-11-10-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2020/11/10/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2020-11-10-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-01-22-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/01/22/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-01-22-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-02-28-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/02/28/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-02-28-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-05-08-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/05/08/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-05-08-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-09-03-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/09/03/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-09-03-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-11-15-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/11/15/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-11-15-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-11-15-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/11/15/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-11-15-1-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-11-27-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/11/27/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-11-27-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2021-12-28-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2021/12/28/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2021-12-28-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-01-05-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/01/05/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-01-05-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-10-12-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/10/12/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-10-12-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-12-06-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/12/06/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-12-06-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-12-16-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/12/16/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-12-16-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-12-19-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/12/19/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-12-19-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2022-12-21-0-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2022/12/21/0/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2022-12-21-0-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-contents-2024-02-20-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/doputer.github.io/doputer.github.io/contents/2024/02/20/1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-contents-2024-02-20-1-index-mdx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

